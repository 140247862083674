import { Button, Image } from 'react-bootstrap';
import BlogCarousel from './BlogCarousel';
import { useState } from 'react';

const BlogPage = () => {
  const [disabled, setDisabled] = useState(false);

  const handleShowAll = () => {
   // setPostsCards(postsMapAll);
    setDisabled(true);
  };


  return (
    <>
      <Image src='/images/blog/blog_banner_1.png' className='w-100' id='blogBanner'></Image>
      <section className='d-flex align-items-center w-100 bg-green-light-cu-2 p-lg-5 py-lg-5 py-5 flex-column gap-5' id="blog">
        <BlogCarousel size="large" justify="center" />
        <div className='d-flex justify-content-center'>
          <Button style={{ backgroundColor: "#8FB93E", borderColor: "#8FB93E" }} className={`carousel-button  rounded-1 ` + (disabled ? 'd-none' : '')} onClick={handleShowAll} >Ver Todos</Button>
        </div>
      </section>
    </>
  );
}

export default BlogPage;