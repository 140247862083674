import { Routes, Route } from "react-router-dom";
import Flag from '../../components/Flags.jsx';
import Somos from '../../components/Somos';
import Steps from '../../components/Steps';
import Review from '../../components/Review';
import Contact from '../../components/Contact';
import Hero from '../../components/Hero';
import QuoteForm from '../../components/FormQuote';
import Thanks from '../../components/Thanks';
import { useState } from "react";
const HomeRoutes = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Routes>
      <Route path="/" element={
        <>
          <Hero />
          {
            isSubmitted
              ? <Thanks newQuote={() => setIsSubmitted(false)} />
              : <QuoteForm setIsSubmitted={() => setIsSubmitted(true)} />
          }
          <Flag />
          <Somos />
          <Steps />
          <Review />
          <Contact />
        </>} />
    </Routes>
  )
}

export default HomeRoutes;