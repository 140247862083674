import propTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PostFooterBar = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <hr className="divider-solid-green" />
      <div className="d-flex justify-content-between" id='postNavFooter'>
        <a
          href="https://www.instagram.com/carrosacentroamerica?igsh=MW1iZzJ6dmd0bHZsNw=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            className="post-p d-inline-block"
            src="/images/blog/assets/instagram-dark-outline.svg"
            alt="instagram logo"
            href="https://www.instagram.com/carrosacentroamerica?igsh=MW1iZzJ6dmd0bHZsNw=="
            target="_blank"
            width="35"
            height="35"
            style={{ cursor: 'pointer', marginBottom: '0' }}></Image>
        </a>


        <Button
          key={item.id}
          className="post-p  text-decoration-none"
          variant="link"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(item.url)} >{item.title}</Button>
      </div>
    </>
  )
};

export default PostFooterBar;

PostFooterBar.propTypes = {
  item: propTypes.shape({
    id: propTypes.number.isRequired,
    title: propTypes.string.isRequired,
    url: propTypes.string.isRequired
  }).isRequired
}