import { Button } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';


const Hero = () => {
  const customNavigate = useCustomNavigate();
  const buttonStyle = {
    width: "10rem",
  }

  return (
    <section id='hero' style={{ height: "120vh", marginTop: "-4rem" }} className=" d-flex justify-content-center align-items-center hero-background blu-background-filter">
      <div className='container d-flex flex-column m-lg-5 px-lg-5 text-center'>
        <h1 className='hero-primary-text'>
          Tu auto en Centroamérica sin complicaciones
        </h1>
        <p className='hero-secondary-text mx-lg-5 px-lg-5'>
          Envío profesional de Autos sin Preocupaciones, con Seguridad y Confiabilidad Garantizadas.
        </p>
        <div className='d-none d-lg-flex justify-content-center gap-3'>
          <Button variant="primary" style={buttonStyle} onClick={() => customNavigate('/','cotizar')} >Cotizar</Button>
          <Button variant="outline-light" style={buttonStyle} onClick={() => customNavigate('/','somos')}>Descubrenos</Button>
        </div>
      </div>
    </section>
  );
}

export default Hero;