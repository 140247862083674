import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navi from './components/Navi';
import HomeRoutes from './pages/home/HomeRoutes';
import BlogsRoutes from './pages/blog/BlogRoutes'

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_16_p94bzx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    ReactGA.initialize('AW-11239929039');
  }, [])

  return (
    <Router>
      <div className="App">
        <Navi />
        <Routes>
          <Route path="/*" element={<HomeRoutes />} />
          <Route path="/blog/*" element={<BlogsRoutes />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
